import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import ProductArchivePage from '../ProductArchivePage'

const productArchive = props => {
    const {
        data: {
            filteredPosts: {nodes, pageInfo},
            allWpPage,
            allWp
        },
        pageContext: {archivePath, uri},
        location,
    } = props

    const pageData = allWpPage.nodes[0]
    const categoryData = allWp.nodes[0].optionPage.themeOptions;
    const categoriesData = categoryData.orderCategories;
    let productsData = [];
    
    nodes.map(item => {
        productsData.push(item);
    })

    const {title} = pageData.seo

    let isHideOnDesktop = true;

    return (
        <Layout bodyClass='archive' isFooterPromo={false} isHideText={true} isHideOnDesktop={isHideOnDesktop}
                 isGoogleMapsInFooterPromo={true}>
            <Seo title={title ? title : pageData.title} meta={pageData.seo} uri={uri}/>
            <ProductArchivePage
                location={location}
                archivePath={archivePath}
                pageInfo={pageInfo}
                pageData={pageData}
                categories={categoriesData}
                products={productsData}
            />
        </Layout>
    )
}

export const query = graphql`
    query archiveProduct($categoryDatabaseId: Int) {
        filteredPosts: allWpProduct(
            filter: { productCategories: { nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } } } }
            sort: {order: ASC, fields: date}
        ) {
            nodes {
                ...ProductPreview
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                currentPage
                pageCount
            }
        }
        allWpPage(filter: {slug: {in: ["menu","menu-2"]}}) {
            nodes {
                id
                title
                uri
                seo {
                    title
                    metaDesc
                    focuskw
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    opengraphSiteName
                    opengraphDescription
                    opengraphImage {
                        link
                    }
                    twitterDescription
                    twitterTitle
                    twitterImage {
                        link
                    }
                    fullHead
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            ...ProductThumbnail
                        }
                    }
                }
                schemaOrgJson {
                    schemaOrgJson
                }
                instagramSection {
                    instagramTitle
                    instagramContent
                    instagramLink {
                        instagramLinkTitle
                        instagramLinkSubtitle
                        instagramLinkLink
                        instagramLinkIcon {
                            altText
                            localFile {
                                ...IconImage60
                            }
                        }
                    }
                    photoType
                    photoList {
                        localFile {
                            ...InstBlock
                        }
                    }
                    
                }
            }
        }
        allWpTermNode(filter: { taxonomyName: { eq: "products" } }, sort: { fields: databaseId }) {
            nodes {
                ... on WpProductCategory {
                    databaseId
                    id
                    name
                    uri

                }
            }
        }
        allWp {
            nodes {
                optionPage {
                    themeOptions { 
                        orderCategories {
                            databaseId
                            id
                            name
                            uri
                        }

                    }
                }
            }
        }
    }
`

export default productArchive
