import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import { Grid } from 'antd'

import * as styles from './product.module.scss'

const ProductCardNew = ({ product }) => {
    const { useBreakpoint } = Grid
    const { sm } = useBreakpoint()

    if (!product) return null

    const {
        product: { productDescription, productPortion, productPrice },
    } = product;
    const productPath = product.uri.replace('product', 'menu');
    const currency = 'Byn';

    return (
        <div id={product.id} className={styles.productMenuCard}>
            <h3 className={styles.productMenuCard__title}>{product.title}</h3>
            <p className={styles.productMenuCard__price}>{parse(productPrice)} {currency}</p>
            <p className={styles.productMenuCard__description}>{productDescription && parse(productDescription)}</p>
        </div>
    )
}

export default ProductCardNew
