import React, {useState, useEffect, useRef} from 'react'

const PictureDiz = ({set, alt, bpsizes}) => {
    const useOnScreen = (ref) => {
        const [isOnScreen, setIsOnScreen] = useState(false);
        const observerRef = useRef(null);
      
        useEffect(() => {
            observerRef.current = new IntersectionObserver(([entry]) => {
                if(entry.isIntersecting){
                    setIsOnScreen(entry.isIntersecting)
                    observerRef.current.disconnect()
                }
            });
        }, []);
      
        useEffect(() => {
            if(ref.current){
                observerRef.current.observe(ref.current);
        
                return () => {
                    observerRef.current.disconnect();
                };
            }
            
        }, [ref]);
      
        return isOnScreen;
    }
    
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

    if (!set) return null;

    const {height, images} = set;
    const srcSet = images.fallback.srcSet.split(',');
    const width = parseInt(srcSet[0].split(' ')[1].replace('w', ''));
    const heightPx = parseInt(width * parseFloat(height));
    const biggestImage = srcSet[srcSet.length - 1].split(' ')[0];

    let sortedSizes = [];

    bpsizes.forEach(bpsize => {
        const strSizeX1 = srcSet.find(str => str.includes(bpsize[0]));
        const strSizeX2 = srcSet.find(str => str.includes(bpsize[1]));

        if(strSizeX1 && strSizeX2){
            const [urlX1, sizeX1] = strSizeX1.split(' ');
            const [urlX2, sizeX2] = strSizeX2.split(' ');

            sortedSizes.push({
                x1: urlX1,
                x2: urlX2
            })
        }else{
            sortedSizes.push({
                x1: biggestImage,
                x2: biggestImage
            })
        }
    })

    if(sortedSizes.length> 0){
        return (
            <picture ref={ref} className={`custom-images ${isVisible ? 'loaded-image' : ''}`}>
                {sortedSizes.reverse().map((sizes, index) => {
                    let {x1, x2} = sizes;

                    let media = '(min-width:769px)';

                    if(index == 1){
                        media = '(min-width:429px)';
                    }else if(index == 2){
                        media = '(min-width:376px)';
                    }else if(index == 3){
                        media = '(min-width:0px)';
                    }

                    return (
                        <source key={`picture-diz-source-${index}`} media={media} srcSet={isVisible ? `${x1} 1x, ${x2} 2x` : ''}/>
                    )
                })}
                <img alt={alt} src={isVisible ? sortedSizes[0].x1 : ''} width={width} height={heightPx} loading='lazy'/>
            </picture>
        )
    }else{
        return (
            <picture>
                <img alt={alt} src={isVisible ? biggestImage : ''} width={width} height={heightPx}/>
            </picture>
        )
    }
}
export default PictureDiz