// extracted by mini-css-extract-plugin
export var list = "product-module--list--HKdg7";
export var list__row = "product-module--list__row--wJVVj";
export var list__title = "product-module--list__title--D2KFK";
export var list__productSlider = "product-module--list__productSlider--N0Rqo";
export var list__productSlider_hideDesk = "product-module--list__productSlider_hideDesk--A6NSK";
export var list__productSlider_noGall = "product-module--list__productSlider_noGall--s7YyW";
export var list__productSliderContainer = "product-module--list__productSliderContainer--feOrf";
export var list__productSliderContainer_one = "product-module--list__productSliderContainer_one--ONdUU";
export var list__productSliderButton = "product-module--list__productSliderButton--mK15v";
export var list__productSliderImageHover = "product-module--list__productSliderImageHover--kI-Q0";
export var list__productSliderPrevArrow = "product-module--list__productSliderPrevArrow--WEG2J";
export var list__productSliderNextArrow = "product-module--list__productSliderNextArrow--eQX-p";
export var list__modalProductSliderPrevArrow = "product-module--list__modalProductSliderPrevArrow--uVzHe";
export var list__modalProductSliderNextArrow = "product-module--list__modalProductSliderNextArrow--mMP+K";
export var list__modalProductSlider = "product-module--list__modalProductSlider--mRYeb";
export var list__modalProductSliderTitle = "product-module--list__modalProductSliderTitle--MVBEK";
export var list__modalProductSliderPrice = "product-module--list__modalProductSliderPrice--w6Tio";
export var list__modalProductSliderDescription = "product-module--list__modalProductSliderDescription--9MSPc";
export var list__modalProductSliderPortion = "product-module--list__modalProductSliderPortion--UclYU";
export var list__innerList = "product-module--list__innerList--SeolH";
export var list__innerList_hideDesk = "product-module--list__innerList_hideDesk--Mbw5-";
export var list__innerList_noGall = "product-module--list__innerList_noGall--sxS+5";
export var list__wrapper = "product-module--list__wrapper--6Yz7y";
export var list__item = "product-module--list__item--xZ1An";
export var productMenuCard = "product-module--productMenuCard--9tVoJ";
export var productMenuCard__title = "product-module--productMenuCard__title--sbXx0";
export var productMenuCard__price = "product-module--productMenuCard__price--VAtpq";
export var productMenuCard__description = "product-module--productMenuCard__description--acK8T";
export var listSlider = "product-module--listSlider--HN5nb product-module--list--HKdg7";
export var post__description = "product-module--post__description--OLrx4";
export var post = "product-module--post--WsFGG";
export var post__withoutImg = "product-module--post__withoutImg--04lqj";
export var post__image = "product-module--post__image--rzvQG";
export var post__content = "product-module--post__content--jmHDj";
export var post__title = "product-module--post__title--uc6TI";
export var post__footer = "product-module--post__footer--plR8r";
export var post__price = "product-module--post__price--FgSoX";
export var product = "product-module--product--XqE+n";
export var product__inner = "product-module--product__inner--YldyG";
export var product__description = "product-module--product__description--DFXe5";
export var product__price = "product-module--product__price--cm1qC";
export var product__promo = "product-module--product__promo--6yFXP";
export var product__promoContent = "product-module--product__promoContent--VG5u6";
export var product__closeButton = "product-module--product__closeButton--8ecZA";
export var product__nextButton = "product-module--product__nextButton--dPUHJ";
export var product__prevButton = "product-module--product__prevButton--5vtJl";
export var categories = "product-module--categories--9IpmF";
export var categories__list = "product-module--categories__list--C0EYm";
export var categories__link = "product-module--categories__link--FQ0Yi";
export var categoriesFullWidth = "product-module--categoriesFullWidth--jgPL+ product-module--categories--9IpmF";
export var categories__linkActive = "product-module--categories__linkActive--MYRJn";
export var gallery = "product-module--gallery--DFvCC";
export var stickyContainer = "product-module--stickyContainer--h-y-r";
export var categoriesHome__list = "product-module--categoriesHome__list--Ie+Rr";
export var categoriesHome__listItem = "product-module--categoriesHome__listItem--CztYZ";
export var categoriesHome__listItemLink = "product-module--categoriesHome__listItemLink--s2301";
export var home = "product-module--home--ryqCd";
export var productSliderTitle = "product-module--productSliderTitle--3GGgM";
export var iconArrowRight = "product-module--iconArrowRight--HSLr-";
export var iconArrowLeft = "product-module--iconArrowLeft--t5GRl";