import React from 'react'
import parse from 'html-react-parser'
import loadable from '@loadable/component'

import ProductList from '../components/Product/ProductList'
const Instagram = loadable(() => import('../components/Instagram'))

const ProductArchivePage = ({ pageData, categories, products }) => {
    const {instagramSection} = pageData

    return (
        <>
            <div className='global-container'>
                <h1 className='global-title-h1 global-title-h1_category'>{parse(pageData.title)}</h1>
                {(products.length > 0 && categories.length > 0) &&
                    <ProductList products={products} categories={categories} pageData={pageData}/>
                }
            </div>
            <Instagram data={instagramSection} bottomSpace={true}/>
        </>
    )
}

export default ProductArchivePage
