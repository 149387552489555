import React, {useState, useRef, useEffect} from 'react'
import { Carousel } from 'antd'

import * as styles from './product.module.scss'
import ProductSliderModal from '../ProductSliderModal/index';
import PictureDiz from '../pictureDiz/pictureDiz'
const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.left >= 0 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

const ProductGallerySlider = ({ gallery, id = null }) => {
    const sliderId = id ? id.replaceAll('=', '') : '';
    const [modalVisibility, setModalVisibility] = useState(false);
    const [lastSlideInView, setLastSlideInView] = useState(false) 
    const slider = useRef(0);

    const openModal = (slideNum) => {
        setModalVisibility(true)
        slider.current.goTo(slideNum, true);
    }

    const modalClose = () => {
        setModalVisibility(false)
    }

    const lastSlideInViewUp = () => {
        const sliderElem = document.querySelector(`.${id.replaceAll('=', '')}`)
        
        if(sliderElem != null){
            const lastSlide = sliderElem.querySelector('.slick-slide:last-child');
            
            if(lastSlide != null){
                setLastSlideInView(isInViewport(lastSlide));
            }
        }
    }

    const slidesInView = () => {
        const sliderElem = document.querySelector(`.${id.replaceAll('=', '')}`)
        
        if(sliderElem != null){
            const slides = sliderElem.querySelectorAll('.slick-slide button');
            
            slides.forEach(slide => {
                if(isInViewport(slide)){
                    slide.setAttribute('data-in-view','true')
                }else{
                    slide.removeAttribute('data-in-view')
                }
            })
        }
    }

    useEffect(() => {
        lastSlideInViewUp()
        slidesInView()

        function handleSliderKeys(event) {
            if(modalVisibility){
                if(event.key == "Escape"){
                    modalClose()
                }else if(event.key == "ArrowRight"){
                    slider.current.next()
                }else if(event.key == "ArrowLeft"){
                    slider.current.prev()
                }
            }
        }

        document.addEventListener('keydown', handleSliderKeys);
        
        return () => {
            document.removeEventListener('keydown', handleSliderKeys);
        };
    });

    const CustomNextArrow = (props) => {
        const { onClick, slideCount, currentSlide, elemClass } = props;

        const disabled = slideCount - 1 == currentSlide || lastSlideInView ? true : false;

        return (
            <button
                className={elemClass}
                onClick={() => {
                    onClick()
                }}
                disabled={ disabled }
            >  <i className={styles.iconArrowRight}/>
            </button>
        );
    }

    const CustomPrevArrow = (props) => {
        const { onClick, slideCount, currentSlide, elemClass } = props;
        
        const disabled = 0 == currentSlide ? true : false;

        return (
            <button
                className={elemClass}
                onClick={() => {
                    onClick()
                }}
                disabled={ disabled }
            >  <i className={styles.iconArrowLeft}/>
            </button>
        );
    }

    const CustomNextArrowModal = (props) => {
        const { onClick, slideCount, currentSlide, elemClass } = props;

        const disabled = slideCount - 1 == currentSlide ? true : false;

        return (
            <button
                className={elemClass}
                onClick={() => {
                    onClick()
                }}
                disabled={ disabled }
            >  <i className={styles.iconArrowRight}/>
            </button>
        );
    }

    const CustomPrevArrowModal = (props) => {
        const { onClick, slideCount, currentSlide, elemClass } = props;
        
        const disabled = 0 == currentSlide ? true : false;

        return (
            <button
                className={elemClass}
                onClick={() => {
                    onClick()
                }}
                disabled={ disabled }
            >  <i className={styles.iconArrowLeft}/>
            </button>
        );
    }

    if (!gallery || gallery.length === 0) return null

    const galleryWithImages = gallery.filter(node => node.featuredImage && node.featuredImage.node && node.featuredImage.node.localFile);
    
    return (
        <section className={`${styles.list__productSlider} ${galleryWithImages.length < 3 ? styles.list__productSlider_hideDesk : ''} ${galleryWithImages.length == 0 ? styles.list__productSlider_noGall : ''}`}>
            <Carousel afterChange={() => {
                lastSlideInViewUp()
                slidesInView()
            }} dots={false} slidesToShow={1} variableWidth={true} slidesToScroll={1}
                      centerMode={false} className={styles.list__productSliderContainer +' '+ sliderId +' '+ (galleryWithImages.length == 1 ? styles.list__productSliderContainer_one : '')} arrows={true} infinite={false}
            nextArrow={ <CustomNextArrow elemClass={styles.list__productSliderNextArrow}/> } prevArrow={<CustomPrevArrow elemClass={styles.list__productSliderPrevArrow}/> }
            >
                {gallery.filter(node => node.featuredImage && node.featuredImage.node && node.featuredImage.node.localFile && node.featuredImage.node.localFile.childImageSharp && node.featuredImage.node.localFile.childImageSharp.gatsbyImageData).map((node, index) => {
                    return (
                        <button key={`slider-button-${index}`} className={styles.list__productSliderButton} onClick={() => openModal(index) }>
                            <PictureDiz set={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={node.title} bpsizes={[[335, 670], [335, 670], [280, 560], [340, 680]]}/>
                            <h3 className={styles.list__productSliderImageHover}>{ node.title }</h3>
                        </button>
                    )
                })}
            </Carousel>
            <ProductSliderModal children={
                <Carousel ref={slider} dots={false} slidesToShow={1} variableWidth={true} slidesToScroll={1}
                centerMode={false} className={styles.list__modalProductSlider} arrows={true} infinite={false} 
                nextArrow={ <CustomNextArrowModal elemClass={styles.list__modalProductSliderNextArrow}/> } prevArrow={<CustomPrevArrowModal elemClass={styles.list__modalProductSliderPrevArrow}/> }
                >
                    {gallery.filter(node => node.featuredImage && node.featuredImage.node && node.featuredImage.node.localFile && node.featuredImage.node.localFile.childImageSharp && node.featuredImage.node.localFile.childImageSharp.gatsbyImageData).map((node, index) => {
                        return (
                            <div key={`slider-slide-${index}`}>
                                <PictureDiz set={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={node.title} bpsizes={[[456, 912], [456, 912], [560, 1120], [880, 1760]]}/>
                                <h3 className={styles.list__modalProductSliderTitle}>{ node.title }</h3>
                                <p className={styles.list__modalProductSliderPrice}>{ node.product.productPrice } Byn</p>
                                <p className={styles.list__modalProductSliderDescription}>{ node.product.productDescription }</p>
                                <p className={styles.list__modalProductSliderPortion}>{ node.product.productPortion }</p>
                            </div>
                        )
                    })}
                </Carousel>
            } visible={modalVisibility} modalClose={modalClose}/>
        </section>
    )
}

export default ProductGallerySlider
