import React, {useEffect, useRef, useState} from 'react'
import * as styles from './modal.module.scss'

const ProductSliderModal = props => {
    const { visible, modalClose, children} = props

    return (
        <div className={visible ? styles.productModal : styles.productModal +' '+ styles.productModal_hidden}>
            <div className={styles.productModal__overlay} onClick={() => modalClose()} />
            <div className={styles.productModal__wrapper}>
                <div className={styles.productModal__innerOverlay} onClick={() => modalClose()} />
                <button className={styles.productModal__close} onClick={() => modalClose()}>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L22.3668 22.3125M22.3668 2L2 22.3125L22.3668 2Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                {children}
            </div>
        </div>
    )
}

export default ProductSliderModal
