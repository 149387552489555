import React, {useEffect, useRef, useState} from 'react'
import ProductCategories from './ProductCategories'
import ProductCardNew from './ProductCardNew'
import * as styles from './product.module.scss'
import ProductGallerySlider from './ProductGallerySlider'

const ProductList = ({products, categories, mode = 'default', pageData}) => {
    const schemaOrgJson = pageData && pageData.schemaOrgJson && pageData.schemaOrgJson.schemaOrgJson ? pageData.schemaOrgJson.schemaOrgJson : false;
    const categoryTitleRefs = useRef([])
    const sliderRef = useRef()
    const [container, setContainer] = useState(null)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)
    const [activeCategoryId, setCategoryActiveId] = useState(categories[0].id)
    const [categoryEntities, setCategoryEntities] = useState(
        categories.map(category => {
            return {
                ...category,
                nodes: [],
            }
        })
    )

    const [sliderProductEntities, setSliderProductEntities] = useState([])

    useEffect(() => {
        products.forEach((product, index) => {
            const productCategories = product.productCategories.nodes
            if (productCategories.length > 0) {
                setCategoryEntities(prevArray => {
                    const currentCategory = prevArray.find(el => el.id === productCategories[0].id) // []

                    if(currentCategory){
                        currentCategory.nodes = [...currentCategory.nodes, product]
                    }

                    return [...prevArray]
                })
            }
        })
    }, [])


    useEffect(() => {
        const sliderProductNodes = categoryEntities.map(category => category.nodes)

        setSliderProductEntities(sliderProductNodes.flat())
        const isBrowser = typeof window !== 'undefined'
        if (isBrowser) {
            const hash = window.location.hash.replace('#', '');

            if (hash) {
                const element = document.getElementById(`${hash}`);
                if (element) {
                    element.scrollIntoView();
                }
            }
        }

    }, [categoryEntities])

    useEffect(() => {
        if (!sliderRef || !sliderRef.current || categoryEntities.length === 0) return

        const activeSlide = sliderRef.current.innerSlider.list
            .querySelector(`[data-index="${activeSlideIndex}"] .${styles.list__wrapper}`)

        if (activeSlide) {
            const currentProductCategory = categoryEntities.find(el => el.id === activeSlide.id)

            setCategoryActiveId(currentProductCategory.id)
        }
    }, [activeSlideIndex])

    const handleCategorySelect = (event, id) => {
        event && event.preventDefault();

        if (!sliderRef || !sliderRef.current) return

        const newArray = [...categoryEntities]
        const selectedCategoryProducts = newArray.filter(item => item.id === id)

        if (
            !selectedCategoryProducts ||
            selectedCategoryProducts.length === 0 ||
            selectedCategoryProducts[0].nodes.length === 0
        )
            return

        const selectedCategoryFirstProductLink = selectedCategoryProducts[0].nodes[0].uri.replace('product', 'menu')

        const filteredSlide = sliderRef.current.innerSlider.list
            .querySelector(`[href="${selectedCategoryFirstProductLink}"]`)
            .closest('.slick-slide')
        const filteredSlideIndex = filteredSlide.getAttribute('data-index')

        filteredSlideIndex && sliderRef.current.goTo(Number(filteredSlideIndex))
    }

    const renderProducts = products => {
        return products.map(product => {

            return product.nodes && product.nodes.length > 0 ? (
                <li key={product.id} className={styles.list__row} id={product.id} ref={element => {
                    const exist = el => element && el.id === element.id
                    const isElementExist = categoryTitleRefs.current.some(exist)

                    element && !isElementExist && categoryTitleRefs.current.push(element)
                }}>
                    <h2
                        className={styles.list__title}
                    >
                        {product.name}
                    </h2>
                    <ProductGallerySlider gallery={product.nodes} id={`gall-${product.id}`}/>
                    <ul className={`${styles.list__innerList} ${product.nodes.filter(node => node.featuredImage && node.featuredImage.node && node.featuredImage.node.localFile).length < 3 ? styles.list__innerList_hideDesk : ''} ${product.nodes.filter(node => node.featuredImage && node.featuredImage.node && node.featuredImage.node.localFile).length == 0 ? styles.list__innerList_noGall : ''}`}>
                        {product.nodes.map(node => (
                            <li className={styles.list__item} key={node.id}>
                                <ProductCardNew product={node}/>
                            </li>
                        ))}
                    </ul>
                </li>
            ) : null
        })
    }

    const useIntersectionObserver = setCategoryActiveId => {
        const headingElementsRef = useRef({})

        useEffect(() => {
            const callback = headings => {
                headingElementsRef.current = headings.reduce((map, headingElement) => {
                    map[headingElement.target.id] = headingElement
                    return map
                }, headingElementsRef.current)

                const visibleHeadings = []

                Object.keys(headingElementsRef.current).forEach(key => {
                    const headingElement = headingElementsRef.current[key]
                    if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
                })

                const getIndexFromId = id => categoryTitleRefs.current.findIndex(heading => heading.id === id)

                if (visibleHeadings.length === 1) {
                    if (visibleHeadings[0].target && visibleHeadings[0].target.id) {
                        setCategoryActiveId(visibleHeadings[0].target.id)
                    }
                } else if (visibleHeadings.length > 1) {
                    const sortedVisibleHeadings = visibleHeadings.sort(
                        (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
                    )

                    if (sortedVisibleHeadings[0].target && sortedVisibleHeadings[0].target.id) {
                        setCategoryActiveId(sortedVisibleHeadings[0].target.id)

                    }
                }

            }

            const observer = new IntersectionObserver(callback, {
                rootMargin: '-10% 0% 0% 0%',
                threshold: 0.1
            })

            mode === 'default' && categoryTitleRefs.current.forEach(element => observer.observe(element))

            return () => observer.disconnect()
        }, [setCategoryActiveId, categoryEntities])
    }

    useIntersectionObserver(setCategoryActiveId)

    const generateSchema = (categoryList, productList) => {
        let createSection = [];
        categoryList.map(item => {
            const innerProducts = productList.filter(product => {
                const catID = product.productCategories.nodes[0];
                return catID && catID.databaseId === item.databaseId
            });

            let listImages = [];

            if(innerProducts.length > 0){
                innerProducts.forEach(prod => {
                    const image = 
                    prod.featuredImage &&
                    prod.featuredImage.node &&
                    prod.featuredImage.node.localFile &&
                    prod.featuredImage.node.localFile.childImageSharp &&
                    prod.featuredImage.node.localFile.childImageSharp.gatsbyImageData &&
                    prod.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images &&
                    prod.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback &&
                    prod.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src 
                    ? prod.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src : false;

                    if(image){
                        listImages.push(`${process.env.GATSBY_SITE_URL}${image.replace('/', '')}`);
                    }
                })
            }

            const newItem = {
                "@type": "MenuSection",
                "name": item.name,
                "image": listImages,
                "hasMenuItem": innerProducts.map(product => {
                    const dataInnerProducts = {
                        "@type": "MenuItem",
                        "name": product.title,
                        "description": product.product.productDescription,
                        "offers": {
                            "@type": "Offer",
                            "price": product.product.productPrice,
                            "priceCurrency": "BYN"
                        }
                    }
                    return dataInnerProducts;
                }),
            };
            
            createSection.push(newItem);
        });

        const schemaOrgJsonObj = JSON.parse(schemaOrgJson);
        let data = false;
        if(schemaOrgJsonObj && schemaOrgJsonObj.hasMenu && schemaOrgJsonObj.hasMenu.hasMenuSection){
            data = {...schemaOrgJsonObj, hasMenu:{
                ...schemaOrgJsonObj.hasMenu,
                hasMenuSection: createSection
            }}
        }

       // { "@context": "https://schema.org", "@type": "Restaurant", "url": "https://hinkalnyagrodno.by/", "name": "Хинкальня", "image": "https://hinkalnyagrodno.by/static/47fee33db0a9d685340dacd96d30e6a9/87757/mobilehero.jpg", "description": "У нас больше чем рецепты, это кулинарная история Грузии в вашей тарелке. Собирайте друзей за большим и уютным столом “Хинкальни” в Гродно.", "telephone": "+375 44 763-59-59", "priceRange": "$$", "address": { "@type": "PostalAddress", "streetAddress": "Урицкого 5", "addressLocality": "Гродно", "postalCode": "230025", "addressCountry": "BY" }, "servesCuisine": ["Грузинская"], "hasMenu": { "@type": "Menu", "hasMenuSection": "createSection", "inLanguage": "Russian" } }

        return data ? JSON.stringify(data) : false;
    };

    const schemaJson = generateSchema(categories, products);
    return (

        <div ref={setContainer} className={mode === 'slider' ? 'global-container' : styles.stickyContainer}>
            {schemaJson &&
                <script type='application/ld+json' dangerouslySetInnerHTML={{__html:  schemaJson}}/>
            }
            <ProductCategories
                mode={mode}
                container={container}
                categories={categories}
                activeCategoryId={activeCategoryId}
                onSelect={handleCategorySelect}
            />
            <ul className={styles.list}>
                {renderProducts(categoryEntities)}
                <span className='menu-info-about-price'>* некоторые позиции меню и цены могут отличаться в разных ресторанах</span>
            </ul>
        </div>
    )
}

export default ProductList
