import React from 'react'
import {Link} from 'gatsby'
import {Affix, Grid, Tabs} from 'antd'
import * as styles from './product.module.scss'

const ProductCategories = ({mode, container, categories, activeCategoryId, onSelect}) => {
    const {TabPane} = Tabs
    const {useBreakpoint} = Grid
    const {sm} = useBreakpoint()

    let labelAllMenu ='Все меню';

    const linkMenu = '/menu';

    const renderProductCategory = ({category}, isIconBefore) => {
        return (
            <Link
                to={mode === 'slider' ? '' : `/menu/#${category.id}`}
                // partiallyActive={true}
                activeClassName={mode === 'slider' ? '' : styles.categories__linkActive}
                className={styles.categories__link}
                onClick={e => mode === 'slider' && onSelect(e, category.id)}
            >
                {/*{isIconBefore ? (*/}
                {/*    category.name === 'Новинки' ? (*/}
                {/*        <sup>New</sup>*/}
                {/*    ) : category.name === 'Сезонное' ? (*/}
                {/*            <SeasonIcon/>*/}
                {/*        )*/}
                {/*        : category.name === 'Season' ? (*/}
                {/*            <SeasonIcon/>*/}
                {/*        ) : null*/}
                {/*) : null}*/}
                <span>{category.name}</span>
                {/*{!isIconBefore ? (*/}
                {/*    category.name === 'Новинки' ? (*/}
                {/*        <sup>New</sup>*/}
                {/*    ) : category.name === 'Сезонное' ? (*/}
                {/*        <SeasonIcon/>*/}
                {/*    ) : category.name === 'Season' ? (*/}
                {/*        <SeasonIcon/>*/}
                {/*    ) : null*/}
                {/*) : null}*/}
            </Link>
        )
    }
    
    return (
        <aside className={mode === 'slider' ? styles.categoriesFullWidth : styles.categories}>

            <Affix
                offsetTop={0}
                target={() => (sm ? container : window)}
            >

                {sm && mode === 'default' ? (
                    <ul className={styles.categories__list}>
                        {categories.map(category => (
                            <li
                                key={category.id}
                                className={activeCategoryId === category.id ? styles.categories__linkActive : ''}
                            >
                                {renderProductCategory({category}, false)}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Tabs
                        activeKey={activeCategoryId ? activeCategoryId : ''}
                        // moreIcon={
                        //     <span>
                        //         {labelMore} <DownIcon/>
                        //     </span>
                        // }
                        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false}/>}
                    >
                        {categories.map(category => (
                            <TabPane key={category.id} tab={renderProductCategory({category}, true)}/>
                        ))}
                    </Tabs>
                )}
            </Affix>
            {mode === 'slider' && (
                <Link to={linkMenu} className='global-button'>
                    {labelAllMenu}
                </Link>
            )}
        </aside>
    )
}

export default ProductCategories
